import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './NotFound.scss';
import Arrow2 from '../../Images/Arrow2.webp'
import error404 from '../../Images/error404.png'

// import { useLocation } from "react-router-dom";
import NavigatorPath from "../NavigatorPath/NavigatorPath"
import { useEffect } from 'react';


export default function NotFound({ }) {
    const location = useLocation()


    useEffect(() => {

    }, [location.pathname]);

    return (
        <div className="NotFound">
            <div className="NotFoundHeader">
                <img src={error404} alt="Air Duct Cleaning In Corona, CA | Air Duct Maintenance In Corona, CA | AC Duct Cleaning In Corona, CA | Dryer Vent Cleaning In Corona, CA" />
                <h1>We could not find the page you were looking for</h1>
                <h2>We don't like to come empty-handed, so here are some useful links:</h2>
            </div>
            <div className="NotFoundMenu">
                <a href={`${"/"}`}>Home Page</a>
                <a href={`/${"book"}`}>Book an appointment online</a>
                <a href={`/${"blog"}`}>Explore The Corona Air Duct Cleaning  Blog For Insightful Articles</a>
                <a href={`/${"sitemaps"}`}>Site Map</a>
            </div>
            <div className="NotFoundNavigatorPath">
                <div className="NavigatorPathBox" >
                    <a href={`/`}>Corona Air Duct Cleaning</a>
                    {/* <img src={error404} alt="Air Duct Cleaning In Corona, CA | Air Duct Maintenance In Corona, CA | AC Duct Cleaning In Corona, CA | Dryer Vent Cleaning In Corona, CA" /> */}
                </div>
                <div className="NavigatorPathBox" >
                    <a href={``} style={{"textDecoration":"unset"}}>404</a>
                </div>
            </div>
        </div>
    )
}
