import React from "react";
import './BlogPage.scss';
import Arrow2 from '../../Images/next.png'
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import parse from 'html-react-parser'
import telephone from '../../Images/telephone.png'
import book from '../../Images/book.png'
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useLocation, useNavigate } from "react-router-dom";


export default function BlogPage({ FaqList, Blogs }) {
    const location = useLocation()
    const navigate = useNavigate()
    const [SpecificBlog, setstate] = useState(Blogs[location.pathname.split('_').slice(-1).pop()]);
    const [menuBarPhoneOpen, setmenuBarPhoneOpen] = useState(false);
    const [windowSize, setwindowSize] = useState(window.innerWidth);

    const useCallback = (event) => {
        setwindowSize(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', useCallback);
        return () => {
            window.removeEventListener('resize', useCallback);
        };
    }, [useCallback]);

    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }

    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());

    return (

        <div className="BlogPage">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Air Duct Cleaning Corona, CA | {SpecificBlog.BlogPath}</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content={`Air Duct Cleaning Corona, CA | ${SpecificBlog.BlogPath}`} data-react-helmet="true" />
                <meta name="og:title" property="og:title" content={`Air Duct Cleaning Corona, CA | ${SpecificBlog.BlogPath}`} data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="articleId" content={SpecificBlog.BlogId} />
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content={SpecificBlog.Title} />
                <meta name="SubCategories" content={SpecificBlog.BlogPath} />
                <meta name="FunnelStage" content="upper" />
                <meta name="Published" content="20240410" />
                <meta name="Modified" content="20240410" />
                <meta name="Objective" content="education, seo" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content={`https://www.coronaairduct.org/blog/${SpecificBlog.BlogPath.replace(/ /g, "_") + "_" + (SpecificBlog.BlogId - 1)}`} data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content={SpecificBlog.Discription} data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content={SpecificBlog.Discription} data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content={`Air Duct Cleaning Corona, CA | ${SpecificBlog.BlogPath}`} data-react-helmet="true" />
                <meta name="keywords" content="Air Duct Cleaning, Air Duct Inspection, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct services, Air Duct service, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Insulation, Air Duct Installation, Air Duct Liner Repair, Air Duct Pointing, Air Duct Rain Cap Installation, Air Duct Repair, Air Duct Crown Repair, Air Duct Restoration, Air Duct Flue Installation, Air Duct Flue Repair, ​Air Duct Construction, Air Duct Vent Installation, Air Duct Flashing, Flexible Air Duct Liner Installation, Air Duct Damper Repair, Air Duct Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap, Blog" data-react-helmet="true" />
            </Helmet>
            <head className="BlogPageHeader">
                <div className="BlogPageHeaderTopTop">
                    <a href="/blog">
                        <img src={Arrow2} alt={SpecificBlog.BlogPath} onClick={() => navigate(`/blog`)} />
                    </a>
                    <h1>{SpecificBlog.Title}</h1>
                </div>
                <div className="BlogPageHeaderTopCategory">
                    <a href={`/blog?category=${SpecificBlog.CategoryPath}`}>{SpecificBlog.Category}</a>
                </div>
            </head>
            <article className="ArticleMainArticle">
                <div className="ArticleMain">
                    <div className="BlogPageHeaderImage">
                        <img src={SpecificBlog.Photos} alt={`${SpecificBlog.BlogPath} | Corona Air Duct Cleaning  | SENEX | Business | Busniess Laon`} />
                        <span>
                            <h4>Publishes in Insight {SpecificBlog.Date}</h4>
                            <h5>{readingTime(SpecificBlog)} MIN READ</h5>
                        </span>
                        <div className="BlogPageHeaderImagePromise" >
                            <div className="BlogPageHeaderImagePromiseTop">
                                <div className="BlogPageHeaderImagePromiseTopLeft">
                                    <h2>Corona Air Duct Cleaning Near Me</h2>
                                </div>
                                <a href="/apply">SCHEDULE ONLINE</a>
                            </div>
                            <p>Air duct repair is essential for maintaining the safety and functionality of your home's HVAC system. Over time, ducts may develop problems like cracks, leaks, and structural damage that can jeopardize their integrity and efficiency. Regular inspections and timely repairs are crucial to ensuring your air ducts remain safe and efficient. In this blog, we’ll discuss the importance of air duct repair, common issues that can arise, and the benefits of addressing these problems promptly.</p>
                        </div>
                    </div>
                    <div className="BlogPagesList">
                        <div className="BlogPagesListBox">
                            <div className="BlogPagesListBoxRight">
                                {parse(SpecificBlog.Text)}
                            </div>
                        </div>
                    </div>
                    <div className="BlogsListFaq">
                        <div className="BlogsListFaqHead">
                            <h1>Frequently asked questions</h1>
                        </div>
                        <div className="BlogsListFaqList">
                            {FaqList.slice(getNumberFaq, (getNumberFaq + 3)).map((A, index) =>
                                <article key={index} className="FaqListBox">
                                    <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} key={index} className="BlogsListFaqBox" >
                                        <h1>{A.question}</h1>
                                        <img src={Arrow2} alt="Air Duct Cleaning In Corona, CA | Air Duct Maintenance In Corona, CA | AC Duct Cleaning In Corona, CA | Dryer Vent Cleaning In Corona, CA" />
                                    </a>
                                </article>
                            )}
                        </div>
                    </div>
                </div>
                {windowSize > 690 ?
                    <aside className="ArticleAside">
                        <nav>
                            <p>ON THIS PAGE</p>
                            <ul>
                                {SpecificBlog.BlogPathMenu.map((A, index) =>
                                    <li>
                                        <a href={`#${A}`}>{A}</a>
                                    </li>
                                )}
                                <a className="BlogAsideCall" href="tel:951-476-2729">CALL 951-476-2729<img src={telephone} alt="Air Duct Cleaning In Corona, CA | Air Duct Maintenance In Corona, CA | AC Duct Cleaning In Corona, CA | Dryer Vent Cleaning In Corona, CA" /></a>
                                <a className="BlogAsideCall" href="/book">SCHEDULE ONLINE<img src={book} alt="Air Duct Cleaning In Corona, CA | Air Duct Maintenance In Corona, CA | AC Duct Cleaning In Corona, CA | Dryer Vent Cleaning In Corona, CA" /></a>

                            </ul>
                        </nav>

                    </aside>
                    :
                    <aside className="ArticleAsidePhone">
                        <nav>
                            <span >
                                <div className="ArticleAsidePhoneHeader2" onClick={() => setmenuBarPhoneOpen(true)}>
                                    <p>ON THIS PAGE</p>
                                    <img src={Arrow2} alt="Air Duct Cleaning In Corona, CA | Air Duct Maintenance In Corona, CA | AC Duct Cleaning In Corona, CA | Dryer Vent Cleaning In Corona, CA" />
                                </div>
                                {menuBarPhoneOpen ?
                                    <div className="ArticleAsidePhoneMainMain">
                                        <div className="ArticleAsidePhoneBoxShadow" onClick={() => setmenuBarPhoneOpen(false)}></div>
                                        <div className="ArticleAsidePhoneMain" >
                                            <div className="ArticleAsidePhoneHeader" onClick={() => setmenuBarPhoneOpen(false)}>
                                                <p>ON THIS PAGE</p>
                                                <img src={Arrow2} alt="Air Duct Cleaning In Corona, CA | Air Duct Maintenance In Corona, CA | AC Duct Cleaning In Corona, CA | Dryer Vent Cleaning In Corona, CA" />
                                            </div>
                                            <ul>
                                                {SpecificBlog.BlogPathMenu.map((A, index) =>
                                                    <li>
                                                        <a
                                                            onClick={() => setmenuBarPhoneOpen(false)}
                                                            href={`#${A}`}
                                                        >
                                                            {A}
                                                        </a>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </span>
                        </nav>

                    </aside>
                }
            </article>
            <div className="BlogPageFooter">
                {windowSize > 690 ?
                    null
                    :
                    <aside className="ArticleAside">
                        <nav>
                            <p>ON THIS PAGE</p>
                            <ul>
                                {SpecificBlog.BlogPathMenu.map((A, index) =>
                                    <li>
                                        <a href={`#${A}`}>{A}</a>
                                    </li>
                                )}
                            </ul>
                        </nav>

                    </aside>
                }
                <div className="BlogsListReview">
                    <div className="BlogsListReviewHead">
                        <h1>Related Articles</h1>
                    </div>
                    <div className="BlogsListReviewList">
                        {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>
                            <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                                <article>

                                    <div className="BlogsListReviewBoxLeft">
                                        <img src={A.Photos} alt={A.BlogPath} />
                                    </div>
                                    <div className="BlogsListReviewBoxRight">
                                        <h1>{A.Title}</h1>
                                        <span>
                                            <h5>{readingTime(A)} MIN READ</h5>
                                            <h4>{A.Date}</h4>
                                        </span>
                                    </div>
                                </article>

                            </a>
                        )}
                    </div>
                </div>

            </div>
            <NavigatorPath />
        </div >
    )
}

